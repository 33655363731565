<template>
  <div class="content">
    <div class="return" v-if="clientId" @click="back"><img class="backImg"  src="@/assets/images/back.png" alt /></div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />优惠券信息
        </div>
      </div>
      <div class="table">
        <el-descriptions  size="small" class="margin-top" direction="vertical" :column="6" border>
          <el-descriptions-item>
            <template slot="label">名称</template>{{detail.name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">优惠券类型</template>
            <span v-if="detail.type == 1">满减券</span>
            <span v-if="detail.type == 2">服务卡</span>
            <span v-if="detail.type == 3">满赠券</span>
            <span v-else>兑换券</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">可使用商品</template>
            <span v-if="detail.goods_id == ''">全部商品</span>
            <el-popover
            v-else
              placement="right"
              width="400"
              trigger="click">
              <el-table :data="detail.goodsList" size="small" :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass">
                <el-table-column  property="name" label="商品名称"></el-table-column>
                <el-table-column property="goods_sn" label="商品编号"></el-table-column>
              </el-table>
              <el-button size="mini" slot="reference" plain type="primary" class="goodsBtn">部分商品</el-button>
            </el-popover>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">使用门槛</template>
            <span v-if="detail.use_threshold ==0">不限</span>
            <span v-else>满{{detail.use_threshold}}元可用</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">面值</template>
            {{detail.denomination}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">状态</template>
            
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">有效期</template>
             {{detail.start_time}}至{{detail.end_time}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">总发行量</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">已领取</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">待领取</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">已使用</template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">未使用</template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <!-- <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />优惠券发放详情
        </div>
      </div>
      <div class="table">
        <el-descriptions  size="small" class="margin-top" direction="vertical" :column="4" border>
          <el-descriptions-item>
            <template slot="label">发放类型</template>
            <span v-if="provide.provide_type == 1">标签选择</span>
            <span v-if="provide.provide_type == 2">门店选择</span>
            <span v-if="provide.provide_type == 3">会员等级</span>
            <span v-if="provide.provide_type == 4">指定会员</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">发放人</template>
            {{provide.provide_create_user_name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">发放时间</template>
            {{provide.provide_time}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">发放明细</template>
            <el-popover
              placement="right"
              width="400"
              trigger="click">
              <el-table :data="provide.dataList" size="small" :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass">
                <el-table-column  property="data_name" label="名称"></el-table-column>
              </el-table>
              <el-button size="mini" slot="reference" plain type="primary" class="goodsBtn">发放
                <span v-if="provide.provide_type == 1">标签</span>
                <span v-if="provide.provide_type == 2">门店</span>
                <span v-if="provide.provide_type == 3">会员等级</span>
                <span v-if="provide.provide_type == 4">会员</span>
              </el-button>
            </el-popover>
          </el-descriptions-item>
          
        </el-descriptions>
      </div>
    </div> -->

    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />优惠券发放记录
        </div>
      </div>
      <div class="table">
        <el-row>
          <el-col :span="24">
            <el-table
              :data="provide"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
              <ElTableColumn label="发放类型" prop="data_name" />
              <ElTableColumn label="发放时间" prop="create_time" width="" />
              <ElTableColumn label="发放人" prop="provide_create_user_name" />
              <ElTableColumn label="发放门店" prop="shop_names" width="" />
              <ElTableColumn label="会员等级" prop="member_names" width="" />
              <ElTableColumn label="筛选新增时间" prop="create_times" width="" />
              <ElTableColumn label="筛选进店时间" prop="come_times" width="" />
              <ElTableColumn label="筛选成交时间" prop="cj_times" width="" />
              <ElTableColumn label="筛选疤痕类型" prop="scar_nams" width="" />
              <ElTableColumn label="筛选来源" prop="source_names" width="" />
              <ElTableColumn label="筛选分类" prop="member_types" width="" />
              <ElTableColumn label="筛选标签" prop="member_label_name" width="" />
              <ElTableColumn label="保有流失" prop="baoyou" width="" />
              <ElTableColumn label="预计发放" prop="should_send" width="" />
              <ElTableColumn label="实际发放" prop="actual_send" width="" />
              
            </el-table>
            
          </el-col>
        </el-row>
      </div>
    </div>
    
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { getCardDetail,provideCoupon } from "@/api/card";
class Search {
  staffName = ""; // 客户名称
  staffTel = ""; // 客户名称
  statistics = 0; // 统计范围
}
class Options {
  constructor() {
    this.statistics = [
      { id: 0, name: "全部" },
      { id: 1, name: "选项1" },
      { id: 2, name: "选项2" }
    ]; // 代理人
  }
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "StaffList",
  components: {
    pagination
  },
  data() {
    return {
      data: [{}],
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      detail: {}, // 优惠券详情
      idList: "",
      provide:[],
      clientId:''
    };
  },

  mounted() {
    this.getDetail();
    this.clientId = this.$route.query.clientId
    
  },
  methods: {
    getDetail() {
      getCardDetail({ id: this.$route.query.id,type:0 }).then(res => {
        this.detail = res;
      });
      provideCoupon({ id: this.$route.query.id }).then(res => {
        this.provide = res.data;
      });
    },
    // 返回
    back(){
      const query = { id: this.$route.query.clientId,activeName:'third' }
      this.$router.push({ name: '客户详情', query });
     },
    getList() {
    },
    // 点击详情
    tapDetail(id) {
      console.log(id);
      this.$router.push("./serveDetail");
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
      console.log(this.idList);
    }
  }
};
</script>

<style lang="scss" scoped>
.content{
  position: relative;
   .return{
    position: fixed;
    right: 20px;
    top: 71px;
    z-index: 999;
    .backImg {
      margin-right: 14px ;
      width: 32px;
      height: 100%;
      cursor: pointer;
      vertical-align: middle;
  }
  }
}
.conMsg {
  margin-bottom: 30px;
  
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label{
  background: rgb(249, 250, 252);
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 20px 10px 10px;
  margin-bottom: 20px;
  border-top: 2px dotted #ddd;
}

.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.goodsBtn{
  border: 0;
}
.el-button:focus, .el-button:hover{
  background: #33bea721;
  color: #33bea7;
}
</style>
